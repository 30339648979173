<template lang="pug">
  tree.map-wrapper(
    :tree="globalTree")
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Tree from '@/components/tree-2/Tree'

export default {
  name: 'Index',

  components: {
    Tree
  },

  computed: {
    ...mapState('trees', [
      'globalTree',
      'isLoadLazyTree'
    ])
  },

  async mounted () {
    if (!this.globalTree.props) {
    await this.loadTreeById({ id: null, lazy: true })
    }
    if (this.isLoadLazyTree) {
      this.loadTreeById({ id: null, lazy: false })
    }
  },

  methods: {
    ...mapActions('trees', [
      'loadTreeById'
    ])
  }
}
</script>

<style lag="scss" scoped>
  .map-wrapper {
    height: calc(100vh - 80px);
    width: 100%;
  }
</style>
